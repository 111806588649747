.notes-view-container{
    font-size: 14px;
    .thig-grid.mat-elevation-z8{
        box-shadow: none;
    }
    .no-data{
        text-align: center;
    }
    .note_title{
        padding: 3px;
        font-size: 18px;
    }
    .btn-custom{
        font-size: 12px;
        margin-top: 3px;
        position: relative;
        vertical-align: middle;
        bottom: 4px;
        font-size: 12px;
        margin-right: 5px;
        &.blue{
            color: $white;
            background-color: #337ab7;
            border-color: #2e6da4;
        }
        &.small{
            @include btn-small();
        }
    }

    .pull-right{
        float: right;
    }

    .add-notes-btn{
        background-color: $astral;
        border-color: $astral;
        color: $greyLight2;
        font-size: 12px;
        &:hover {
            background-color: $greyLight2;
            border-color: $greyLight2;
            color: $regalBlueTH;
        }
    }

    .selected-notes {
        .hide-policy {
        display: none;
        }
        h5 {
        font-size: 18px;
        color: $headerBlue;
        button.btn-custom {
            margin-left: 10px;
        }
        }
        .notes-list {
            font-size: 14px;
            list-style: none;
            max-height: 380px;
            padding-left: 0;
            overflow-y: auto;
            li {
                background-color: $greyLight4;
                margin-bottom: 10px;
                padding: 10px;
                border: 1px solid $blackLight;
                .content {
                overflow-wrap: break-word;
                margin-top: 10px;
                background-color: $white;
                padding: 10px;
                border: 1px solid $blackLight;
                border-radius: 5px;
                }
            }
        }
        .fa.fa-print::before{
            font-family: 'Material Icons';
            content: 'print';
        }
        .fa.fa-eye::before{
            font-family: 'Material Icons';
            content:'remove_red_eye' 
        }
    }
    
    pre {
        padding: 9.5px;
        margin: 0 0 5px;
        font-size: 13px;
        line-height: 1.42857;
        color: #333333;
        word-break: break-all;
        word-wrap: break-word;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    
    .note_header{
        position: relative;
    }
    
    .noteCollapseIcon{
        position: absolute;
        right: 3%;
        bottom: 1%;
    }
    
    .note_detail{
        background: #CDE1F0;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        pre {
            white-space: pre-wrap;
            background: #ffffff;
            word-break: normal;
        }
    }
    .note-icon{
        font-size: 18px;
        width: 20px;
        height: 20px;
    }
}