//buttons

@mixin btn-small {
  min-height: 32px;
  min-width: 65px;
  font-weight: 600;
  font-size: 12px;
}

.th-btn-primary {
  background-color: #487c9d;
  border-color: #487C9D;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #345a72;
    border-color: #345a72;
  }
}

.th-btn-secondary {
  background-color: $sanMarino;
  border-color: $sanMarino;

  &:hover,
  &:active,
  &:focus {
    background-color: $astral !important;
    border-color: $astral !important;
  }
}

//.btn.btn-label-brand {
//  background-color: rgba(0, 65, 112, 0.1);
//  color: $regalBlue !important;
//
//  &:hover {
//    background-color: $regalBlue !important;
//    color: $white !important;
//  }
//}

.btn-success {
  background-color: $spireTeal;
  border-color: $spireTeal;
}

.btn-danger:hover {
  background-color: #fd397a;
  border-color: #fd397a;
}

.th-btn-outline-success {
  color: $spireTeal;
  border-color: $spireTeal;
  //font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: $spireTeal;
    color: white;
  }
}

.th-btn-default {
  background-color: $astral;
  border-color: $astral;
  color: $greyLight2;

  &:hover,
  &:active,
  &:focus {
    background-color: $greyLight2;
    border-color: $greyLight2;
    color: $regalBlueTH;
  }
}

.btn-secondary {
  color: #fff;
  background-color: #8c9aa7;
  border-color: #8c9aa7;
}

.th-btn-outline-brand {
  background-color: $greyLight2;
  border-color: $greyLight2;
  color: $regalBlueTH;
  //font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: $astral;
    border-color: $astral;
    color: $greyLight2;
  }
}

.th-btn-outline-brand-simple {
  background-color: $greyLight2;
  border-color: $greyLight2;
  color: $regalBlue;
  //font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: $regalBlue;
    border-color: $regalBlue;
    color: $greyLight2;
  }
}

.th-btn-outline-danger {
  color: #fd397a;
  border-color: #f8e8ed;
  background-color: #f8e8ed;
  //font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    background-color: #fd397a;
    border-color: #fd397a;
    color: white;
  }
}

.btn-outline-green {
  color: #0abb87;
  border-color: #0abb87;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #0abb87;
  border-color: #0abb87;
}

.th-btn-cancel {
  background-color: transparent;
  border: 1px solid #ebedf2;
  color: #6c7293;
  //font-weight: 600;
  &:hover,
  &:active,
  &:focus {
    color: #ffffff;
    background: #374afb;
    border-color: #374afb;
  }
}

.th-btn-tooltip {
  margin-left: 10px;
  padding: 9px 0 0;
  background-color: transparent;
  border: none;
  i {
    font-size: 24px !important;
  }
}

.edit-icon {
  color: #4774f3;
}

.delete-icon {
  color: #cd5c5c;
}

.copy-icon {
  color: #4774f3;
}

.th-btn-icon {
  background-color: transparent;
  border: none;
}

.btn.btn-pill {
  border-radius: 2rem;
}

.btn.btn-upper {
  text-transform: uppercase;
}

.btn.btn-bold {
  font-weight: 600;
}

.btn.btn-normal {
  font-weight: 500;
}

.btn.btn-boldest {
  font-weight: 700;
}

.btn.btn-thin {
  font-weight: 400;
}

.btn.btn-lower {
  text-transform: lowercase;
}

.btn.btn-wide {
  padding-left: 1.05rem;
  padding-right: 1.05rem;
}

.btn.btn-wider {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.btn.btn-widest {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.btn.btn-tall {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
}

.btn.btn-taller {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.btn.btn-tallest {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.btn.btn-font-sm {
  font-size: 13px;
}

.btn.btn-font-smaller {
  font-size: 11.375px;
}

.btn.btn-font-md {
  font-size: 1rem;
}

.btn.btn-font-lg {
  font-size: 1.1rem;
}

.btn.btn-default {
  min-height: 37px;
  min-width: 80px;
  font-weight: 600;
  font-size: 13px;
  @media (min-width: 992px) and (max-width: 1240px) {
    min-width: 65px;
  }
}

.btn.btn-small {
  @include btn-small();
}

.btn.btn-loc {
  min-width: 0;
}

.btn.btn-smaller {
  min-height: 30px;
  min-width: 55px;
  font-weight: 600;
  font-size: 11px;
}

.no-side-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header_main {
  width: 100%;
}

.header_subclass {
  display: inline-flex;
  margin-bottom: -20px;
  margin-top: -20px !important;
}

.header_title_subclass {
  padding: 20px;
}

.header_subclass div label {
  text-align: left;
  color: #12568d;
  font-weight: bold;
  font-size: 14px;
}

.header_subclass div span {
  font-weight: bold;
  font-size: 14px;
}

.header_main hr {
  border-bottom: 0 none;
  border-top: 1px solid #000000;
}

.claimsIndicators {
  text-align: center;
  padding: 10px 20px;
}

.claimsIndicators div {
  display: inline-block;
  padding: 0 10px;
}

.claimsIndicators .dash {
  font-size: 15px;
}

.claimsInfoDetails {
  color: #000000;
  font-size: 12px;
}

.claimsInfoDetails > div {
  padding: 0 10px 0 0 !important;
}

.claimsInfoDetails .mainTypeLabel {
  font-size: 16px;
  font-weight: bold;
  color: #12568d;
}

.claimsInfoDetails .claimsInformation .row {
  margin: 0;
  padding: 0px;
}

.claimsInfoDetails .claimsInformation div.claimContainer {
  padding: 0;
}

.claimsInfoDetails .claimsInformation div.claimContainer .claimItem {
  padding: 0;
}

.claimsInfoDetails div label {
  font-weight: bold;
  color: #12568d;
  text-align: right !important;
}

.claimsInfoDetails_display {
  display: inline-flex;
}

.claimsInfoDetails .mainTypeLabel {
  font-size: 16px;
  font-weight: bold;
  color: #12568d;
}

.claimsInfoDetails .policyDetails div.policyContainer {
  padding: 0;
}

.claimsInfoDetails .policyDetails div.policyContainer .policyItem {
  padding: 0;
}

@media print {
  .doNotPrint {
    display: none !important;
  }
}
