.icon-size {
    width: 16px !important;
    height: 16px !important;
}

.icon {
    background-image: url('../../images/icons.png');
    background-repeat: no-repeat;
}

.icon2 {
    background-image: url('../../images/icons2.png');
    background-repeat: no-repeat;

@extend .icon-size;
}

img.icon {
@extend .icon-size;
}

/* icons */
.accept { background-position: -18px 0px; }
.add { background-position: -36px 0px; }
.arrow-branch { background-position: -54px 0px; }
.arrow-down-off { background-position: -72px 0px !important;}
.arrow-down-small-blue-unfixed { background-position: -90px 0px; }
.arrow-down-small-blue { background-position: -94px -4px; }
.arrow-down-small-unfixed { background-position: -108px 0px; }
.arrow-down-small { background-position:-112px -4px; }
.arrow-down { background-position: -126px 0px; }
.arrow-refresh-small { background-position: -144px 0px; }
.arrow-refresh { background-position: -162px 0px; }
.arrow-right { background-position: -180px 0px; }
.arrow-up-off { background-position: -198px 0px !important;}
.arrow-up-small-blue-unfixed { background-position: -216px 0px; }
.arrow-up-small-blue { background-position: -221px -3px; }
.arrow-up-small-unfixed { background-position: -234px 0px; }
.arrow-up-small { background-position:-239px -3px; }
.arrow-up { background-position: -252px 0px; }
.basket-remove { background-position: -270px 0px; }
.calculator { background-position: -288px 0px; }
.chart-bar { background-position: -306px 0px; }
.coins { background-position: -324px 0px; }
.color-swatch { background-position: -342px 0px; }
.creditcards { background-position: -360px 0px; }
.cross-off { background-position: -378px 0px !important; }
.cross { background-position: -396px 0px; }
.date-glow { background-position: -414px 0px; }
.date { background-position: -432px 0px; }
.delete { background-position: -450px 0px; }
.details { background-position: -468px 0px; }
.disk { background-position: -486px 0px; }
.email-add { background-position: -504px 0px; }
.email-delete { background-position: -522px 0px; }
.email { background-position: -540px 0px; }
.error { background-position: -558px 0px; }
.notice { background-position: -558px 0px; }
.exclamation { background-position: -576px 0px; }
.group { background-position: -594px 0px; }
.help { background-position: -612px 0px; }
.hourglass { background-position: -630px 0px; }
.house { background-position: -648px 0px; }
.information-green { background-position: -666px 0px; }
.information { background-position: -684px 0px; }
.key { background-position: -702px 0px; }
.lightning { background-position: -720px 0px; }
.link-break { background-position: -738px 0px; }
.link { background-position: -756px 0px; }
.lock-delete { background-position: -774px 0px; }
.lock-open { background-position: -792px 0px; }
.lock { background-position: -810px 0px; }
.minus { background-position: -828px 0px; }
.money-delete { background-position: -846px 0px; }
.money-dollar { background-position: -864px 0px; }
.money { background-position: -882px 0px; }
.note { background-position: -900px 0px; }
.page-copy { background-position: -918px 0px; }
.page-stack { background-position: -936px 0px; }
.page-white-gear { background-position: -954px 0px; }
.report { background-position: -972px 0px; }
.resize-handle-unfixed { background-position: -990px 0px; }
.resize-handle { background-position: -992px -2px; }
.result-double-next-glow { background-position: -1008px 0px; }
.result-double-next-on { background-position: -1026px 0px; }
.result-double-previous-glow { background-position: -1044px 0px; }
.result-double-previous-on { background-position: -1062px 0px; }
.result-first-off { background-position: -1080px 0px; }
.result-first-on { background-position: -1098px 0px; }
.result-last-off { background-position: -1116px 0px; }
.result-last-on { background-position: -1134px 0px; }
.result-next-glow { background-position: -1152px 0px; }
.result-next-off { background-position: -1170px 0px; }
.result-next-on { background-position: -1188px 0px; }
.result-previous-glow { background-position: -1206px 0px; }
.result-previous-off { background-position: -1224px 0px; }
.result-previous-on { background-position: -1242px 0px; }
.script-go { background-position: -1260px 0px; }
.script { background-position: -1278px 0px; }
.slide-down-active { background-position: -1296px 0px; }
.slide-down-idle { background-position: -1314px 0px; }
.slide-up-active { background-position: -1332px 0px; }
.slide-up-idle { background-position: -1350px 0px; }
.table-key { background-position: -1368px 0px; }
.tick { background-position: -1386px 0px; }
.toggle-minus { background-position: -1404px 0px; }
.collapse { background-position:-1426px -4px; }
.toggle-plus { background-position: -1422px 0px; }
.expand { background-position: -1408px -4px; }
.user-suit { background-position: -1440px 0px; }
.user { background-position: -1458px 0px; }
.wand { background-position: -1476px 0px; }
.world-link { background-position: -1494px 0px; }
.world { background-position: -1512px 0px; }
.wrench-off { background-position: -1530px 0px !important; }
.wrench { background-position: -1548px 0px; }
.zoom { background-position: -1566px 0px; }
.date-stretch { background-position: -1583px 1px; cursor: text; }
.date-glow-stretch { background-position: -1733px 1px; cursor: pointer; }
.grid-split { background-position: -1884px 0px; }
.lock-edit { background-position: -1886px 0px; }
.add-off { background-position: -1902px 0px; }
.script-edit { background-position: -1918px 0px; }
.print { background-position: -1936px 0px; }
.flower { background-position: -1952px 0px; }
.asterisk { background-position: -1968px 0px; }
.pencil { background-position: -1984px 0px; }
.time { background-position: -2000px 0px; }
.clip { background-position: -2016px 0px; }
.ctr { background-position: -2032px 0px; }
.shield { background-position: -2048px 0px; }
.cursor { background-position: -2064px 0px; }
.door-exit { background-position: -2080px 0px; }
.map-zoom { background-position: -2096px 0px; }
.cpic { background-position: -2112px 0px; }
.hourglass-delete { background-position: -2128px 0px; }
.clearinghouse { background-position: -2144px 0px; }
.usaa { background-position: -2160px 0px; }
.lightbulb { background-position: -2176px 0px; }
.book { background-position: -2192px 0px; }
.page-text { background-position: -2208px 0px; }
.exit { background-position: -2224px 0px; }
.magnifier { background-position: -2240px 0px; }
.high-net-worth { background-position: -2256px 0px; }
.spire { background-position: -1601px 0px; }
.prime-plus { background-position: -1711px 0px; }

/*icons 2*/
.application_cascade { @extend .icon2; background-position: -5px -5px;}
.application_view_list { @extend .icon2; background-position: -31px -5px;}
.arrow_rotate_clockwise { @extend .icon2; background-position: -57px -5px; }
.arrow_undo { @extend .icon2; background-position: -83px -5px; }
.book_addresses { @extend .icon2; background-position: -109px -5px; }
.filter_down { @extend .icon2; background-position: -135px -5px; }
.printer { @extend .icon2; background-position: -161px -5px; }
.transmit_go { @extend .icon2; background-position: -187px -5px; }
.symbility { @extend .icon2; background-position: -31px -5px; }
.wrench_orange { @extend .icon2; background-position: -213px -5px;}

.cursor-pointer {
    cursor: pointer;;
}
