.mat-step-label {
  background: #d1d8e0;
  color: #2f74ab !important;
  display: inline-block !important;
  min-width: 180px !important;
  outline: none;
  padding: 10px 0;
  position: relative;
  text-decoration: none;
  font-size: 13px;
  text-align: center;
}
.mat-step-label:hover {
  color: white !important;
}
.mat-step-header.mat-focus-indicator {
  background-color: transparent !important;
}
/* Adds the cut out on the left side of the tab */
.mat-step-label:before {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 20px solid white;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}

/* Adds the arrow on the right side of the tab */
.mat-step-label:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 0 20px 20px;
  border-color: white white white #d1d8e0;
  position: absolute;
  content: "";
  top: 0;
  right: -1px;
  z-index: 2;
}

.mat-step-label-active {
  background-color: #5998c0;
  color: #fff !important;
}

.mat-step-label-selected {
  background-color: #004170 !important;
  color: #fff !important;
}

/* Adds the right arrow after the tab */
.mat-step-label-active:after {
  border-left-color: #5998c0 !important;
}
.mat-step-label-selected:after {
  border-left-color: #004170 !important;
}
.mat-expansion-panel-header:hover {
  background: transparent !important;
}
.mat-stepper-horizontal {
  background-color: transparent;
}
.mat-step-header .mat-step-icon {
  display: none !important;
}
.mat-stepper-horizontal-line {
  border-top-width: 0 !important;
  flex: none !important;
  min-width: 0 !important;
}
.mat-horizontal-stepper-header {
  height: auto !important;
  padding-left: 5px !important;
}
.mat-horizontal-content-container {
  padding: 0 !important;
  overflow: visible !important;
}
.mat-expansion-panel {
  box-shadow: none;
}
.mat-expansion-panel-header {
  height: 38px !important;
}
