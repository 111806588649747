@mixin btn-small {
  min-height: 32px;
  min-width: 65px;
  font-weight: 600;
  font-size: 12px;
}


$text-color: #ecf0f1;
.btn-custom {
  @include btn-small();

  &-blue {
    background-color: #357dab;
    color: $text-color;
  }

  &-red {
    background-color: #DC3545;
    color: $text-color;
    &:hover {
      background-color: #fd397a;
    }
  }

  &-green {
    background-color: #0abb87;
    color: $text-color;
    &:hover {
      background-color: #218838
    }
  }

  &-default {
    @extend .btn-custom-blue
  }

  &-save {
    @extend .btn-custom-green
  }

  &-add {
    @extend .btn-custom-green
  }

  &-discard {
    @extend .btn-custom-red
  }
}

.thig-grid {
  .th-contextmenu{
    background-color: #bccbe9;
  }
}
