@import "src/assets/styles/custom/variables";
@import "./../../../node_modules/bootstrap/scss/bootstrap-grid";

//mat-tooltip
.mat-tooltip {
  border: 1px solid $anakiwaLight3;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  padding: 7px;
  border-radius: 3px;
  font-size: 12px !important;
  background: $white !important;
  color: $black !important;
}

//for material form fields
mat-form-field {
  display: initial !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  padding: 0.4em 0 0.2em 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0 0.8em 0;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(18, 18, 25, 0.22);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(92, 92, 103, 0.22);
}

.mat-input-element:disabled,
.mat-checkbox-disabled {
  color: rgba(95, 88, 88, 0.77);
  cursor: not-allowed !important;
}

.mat-radio-disabled,
.mat-radio-disabled .mat-radio-label {
  cursor: not-allowed !important;
}

.mat-form-field-prefix {
  padding: 1.23em 2px 0.9em 0;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $purple-blue;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: $purple-blue;
}

.mat-form-field-flex {
  font-size: 12px;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: -3.8px !important;
}

.mat-option {
  font-size: 13px;
  height: 35px !important;
}

.mat-error {
  font-size: 11px;
}

.mat-form-field-subscript-wrapper {
  margin-top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 !important;
}

.mat-select-disabled .mat-select-value {
  cursor: not-allowed;
}

// for avoiding long error messages or multiple errors from overlapping with input fileds
// removes bottom padding in case the control is valid or not touched.
mat-form-field {
  &.ng-valid {
    .mat-form-field-wrapper {
      padding-bottom: 1.34em;
    }
  }

  &.ng-invalid,
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      padding-bottom: 7px;
    }
  }

  &.ng-untouched {
    .mat-form-field-wrapper {
      padding-bottom: 1.34em;
    }
  }

  .mat-form-field {
    &-underline {
      position: static;
    }

    &-subscript-wrapper {
      position: static;
    }
  }
}

//customization for radio buttons
.mat-radio-outer-circle,
.mat-radio-inner-circle {
  height: 16px !important;
  width: 16px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $astral;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $astral;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $astral;
}

.mat-radio-label-content {
  padding-bottom: 4px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
  font-size: 13px;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #d1d8e0;
}

.mat-button-toggle-button:focus {
  outline: none;
}

.mat-button-toggle-disabled {
  cursor: not-allowed !important;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #8d8f95;
}

.mat-button-toggle-appearance-standard, {
  color: white !important;
  background: #d1d8e0;
}

.mat-button-toggle-checked {
  background-color: #5998c0;
  color: white !important;
}

//customization for selection list check box
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background-color: $astral;
}

.mat-list-base .mat-list-item .mat-list-item-content-reverse,
.mat-list-base .mat-list-option .mat-list-item-content-reverse {
  flex-direction: row !important;
  padding-left: 5px !important;
}

.mat-list-text {
  padding-left: 10px !important;
}

.mat-list-item-disabled {
  pointer-events: visible !important;
  background-color: transparent !important;
}

.mat-pseudo-checkbox-disabled {
  cursor: not-allowed !important;
}

mat-selection-list {
  outline: none !important;
}

//customization for material checkbox
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $astral;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $astral;
}

.mat-checkbox-label-before .mat-checkbox-inner-container {
  margin-left: auto !important;
  margin-right: 0 !important;
}

.mat-checkbox-inner-container {
  height: 14px !important;
  width: 14px !important;
}

.mat-card {
  box-shadow: 0 4px 8px 0 rgba(188, 180, 180, 0.2),
    0 6px 20px 0 rgba(170, 166, 166, 0.19) !important;
  border-radius: 6px !important;
}


//mat spinner
.mat-progress-spinner,
.mat-progress-spinner svg {
  height: 50px !important;
  width: 50px !important;
}

//mat date picker
.mat-form-field-flex {
  align-items: center !important;
}

//angular material tab without routing
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

.mat-tab-label {
  text-decoration: none !important;
  color: #2f74ab;
  opacity: 1 !important;
  height: 40px !important;
  min-width: 120px !important;
  font-size: 14px;
}

.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
  background-color: #5998c0;
  font-weight: 700;
  color: white;
  opacity: 1;
}

.mat-tab-body-content {
  overflow: inherit !important;
}

label {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

h2,
h4 {
  color: $headerBlue;
}

.head-title {
  font-size: 14px;
  font-weight: bold;
  color: rgb(18, 86, 141);
}

.th-nodata {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $blackLight;
  font-size: 14px;
}

.main-container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

hr {
  margin-top: 0.25rem;
}

@include media-breakpoint-up(sm) {
  .rightAlign {
    text-align: right !important;
  }

  .leftAlign {
    text-align: left !important;
  }
}

//Needed for styling the required astrix on the reusable controls
.required::after {
  content: ' *';
  color: #dc3545;
}

.text-align-right {
  text-align: right;
}


.label-renewal,
.label-new .label-open {
  background-color: #5bc0de;
}

.label-notice,
.label-pcancl,
.label-cancel,
.label-closed {
  background-color: #ffffcc;
}


.label-expire,
.label-cash,
.label-quote,
.label-lead,
.label-issue {
  background-color: #f44336;
}

.grid-link {
  color: #2f74ab;
  font-weight: 600;
  //.cdk-overlay-pane {
  //  width: auto !important;
  //}
}

.hide {
  display: none !important;
}

.hand-pointer {
  cursor: pointer;
}

/**Sprite CommonIcon Images**/

.commonIconscard-image,
.commonIconsdefault,
.commonIconsmstile-150x150,
.commonIconsthig-chat,
.commonIconsTHIG_Horizontal_Logo {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(../../../src/assets/images/commonIcons.png);
}

.commonIconscard-image {
  width: 192px;
  height: 192px;
  background-position: 90.5711% 0.600962%;
  background-size: 1066.67%;
}

.commonIconsdefault {
  width: 100px;
  height: 100px;
  background-position: 96.4066% 0.541126%;
  background-size: 2048%;
}

.commonIconsDefDropdown {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.commonIconsmstile-150x150 {
  width: 270px;
  height: 270px;
  background-position: 79.0776% 0.66313%;
  background-size: 758.519%;
}

.commonIconsthig-chat {
  width: 70px;
  height: 70px;
  background-position: 94.9444% 11.5304%;
  background-size: 2925.71%;
}

.commonIconsTHIG_Horizontal_Logo {
  width: 160px;
  height: 56px;
  background-position: 0.766871% 0.771605%;
  background-size: 146.705%;
}

.errorContainer {
  color: red;
  font-size: 13px;
}

//This is need for hiding close claim modal when additional information modal is opened on it.
.hideParentDialog {
  mat-dialog-container {
    visibility: hidden !important;
  }
}

.notes-container{
  max-height: 55vh !important;
  overflow: auto;
}

.spire-logo {
  margin: -17px 0 -20px 0;
}

.mat-menu-panel {
  min-width: auto !important;
  width: auto;
  max-width: 320px !important;
  overflow: hidden !important;
}

.movable  {
  cursor: move !important;
}
