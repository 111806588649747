// colors
$astral: #357dab;
$anakiwa: #9fdeff;
$anakiwaLight: #eef3f7;
$anakiwaLight2: #f7f8fc;
$anakiwaLight3: #eee;
$anakiwa2: #87b5d8;
$blue: #144995;
$blackLight: #7e869e;
$error: #b94a48;
$grey: #f1f2f4;
$greyLight: #f3f4f6;
$greyLight2: #eef2fe;
$greyLight3: #f6f8fe;
$greyLight4: #e9eaeb;
$greyLight5: #f7f8fa;
$havelock: #6bacdd;
$nevada: #4e595d;
$nevada2: #43455c;
$regalBlue: #4774f3;
$regalBlueTH: #004170;
$regalBlueLight: #035794;
$medium-light-blue: #6699cc;
$purple-blue: #7696ef;
$regalBlueDark: #1c1e33;
$regalBlueDark2: #22294c;
$spireTeal: #0abb87;
$spireTealBright: #328189;
$spireTealLight: #dff6ef;
$sanMarino: #426b9f;
$white: #fff;
$black: #000;
$red:#FF0000;
$yellowWarning: #ffb822;
$headerBlue:#12568d;

// colors and backgrounds
.bg-white { background-color: $white; }
.bg-nevada { background-color: $nevada; }
.bg-spireTeal, .bg-success { background-color: $spireTeal; }
.bg-sanMarino { background-color: $sanMarino; }
.bg-regalBlue { background-color: $regalBlue; }
.bg-astral { background-color: $astral; }
.bg-anakiwa { background-color: $anakiwa; }
.bg-havelock { background-color: $havelock; }
.bg-anakiwaLight2 { background-color: $anakiwaLight2; }
.bg-greyLight { background-color: $greyLight; }
.bg-danger { background-color: $error !important; }
.bg-red { background-color: $red !important; }

.color-white { color: $white; }
.color-nevada { color: $nevada; }
.color-nevada2 { color: $nevada2; }
.color-spireTeal { color: $spireTeal; }
.color-sanMarino { color: $sanMarino; }
.color-regalBlue { color: $regalBlue; }
.color-astral { color: $astral; }
.color-anakiwa { color: $anakiwa; }
.color-havelock { color: $havelock; }
.color-error { color: $error; }
.color-blackLight { color: $blackLight; }
.color-blueTH { color: $regalBlueTH; }
.color-black { color: $regalBlueDark}
.color-headerBlue { color: $headerBlue; }
.color-validation {color: $red;}
.color-success {color: $spireTeal;}

.font-size-default { font-size: 1rem; }
.th-font-size-24 { font-size: 1.5rem !important; }
.font-size-14 { font-size: 0.875rem; }
.font-size-sd { font-size: 13px !important;}
.font-size-18 { font-size: 1.25rem; }

// fonts
.font-primary-light { font: 300px 'Roboto'; }
.font-primary-light--italic { font: 300px 'Roboto', italic; }
.font-primary { font: 400px 'Roboto'; }
.font-primary-medium { font: 500px 'Roboto'; }
.font-primary-medium--italic { font: 500px 'Roboto', italic; }
