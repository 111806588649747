@import "./../../../node_modules/bootstrap/scss/bootstrap-grid";

@media (min-width: 1200px) and (max-width: 1600px) {
  .laptop-grid-zip {
    @include make-col(9);
  }
  .laptop-grid-auth {
    @include make-col(6);
  }
  .laptop-offset-auth {
    margin-left: 0;
    padding-left: 30px;
  }
  .no-gutter-laptop {
    margin-left: 0;
    margin-right: 0;

    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}
