@import "src/assets/styles/custom/variables";

//mat table
table {
  width: 100%;
  border: 1px solid #f6f5f5;
}

.table-responsive {
  overflow-y: hidden;
}

.table-title {
  font-size: 14px;
  font-weight: bold;
  color: rgb(18, 86, 141);
  margin-bottom: 1rem;
}

.mat-header-cell, .mat-cell, .mat-footer-cell {
  font-size: 11px !important;
  border-bottom-style: none !important;
  border-right: 1px solid #ddd;
}

.mat-cell, .mat-footer-cell {
  padding: 0 10px 0 5px !important;
}

.mat-footer-cell {
  font-weight: bold;
}

.mat-header-cell {
  font-weight: bold;
  color: $white;
  padding: 0 1px 0 5px !important;
}

.mat-sort-header-arrow {
  color: $white;
}

.mat-row,
.mat-footer-row {
  height: 35px !important;
}

.mat-footer-row {
  background: #c6e1f6;
}

.mat-sort-header-button {
  text-align: left !important;
}

.mat-header-row {
  height: 35px !important;
  background-color: $astral;
  color: $white !important;
}

.mat-row {
  //min-height: 39px;
  color: $nevada;
  border-bottom: 1px solid #f0f3ff;
  border-bottom-width: 0;
  background-color: #f7f8fa;
}

.mat-row:nth-child(2n + 1) {
  background-color: #ddebf6;
}

.mat-row:not(:nth-child(2n + 1)) {
  background-color: $white;
}
//expanded row
tr.collapsed-detail-row {
  height: 0 !important;
  //min-height: 0 !important;
}

tr.row-details-row {
  background-color: rgba(255, 243, 20, 0.09) !important;
}

tr.row-details-row-hidden {
  height: 0 !important;
}

.expanded-detail-container {
  background-color: rgba(255, 243, 20, 0.09);
  overflow: hidden;
}
.alternating-highlight:nth-child(2n + 1).mat-row {
  background-color: #ddebf6;
}
.alternating-highlight:not(:nth-child(4n + 1)).mat-row {
  background-color: $white;
}

.mat-row:not(.expanded-row):not(.highlighted-row):hover {
  background: #f5fc9f;
}

.expanded-detail-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.expanded-detail-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.expanded-detail-items {
  width: 33%;
}

.highlighted-row {
  background: #bccbe9 !important;
}
