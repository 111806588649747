/* You can add global styles to this file, and also import other style files */
@import 'assets/styles/global';
@import 'assets/styles/mobile-mixins';
@import 'assets/styles/custom/variables';
@import 'assets/styles/custom/thig-buttons';
@import 'assets/styles/custom/thig-icons';
@import 'assets/styles/custom/custom-stepper';
@import 'assets/styles/custom/task-notes';
@import 'assets/styles/custom/grid';
@import "../node_modules/@nextgen/task-notes-library/src/themes/citadel-theme.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #f2f0f0 }
#page-container {
  min-height: 100%; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 130px; /* height of your footer */
}
